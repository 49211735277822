<template>
    <div>
        <!--AxBasImagesDetail.vue-->
        <el-container>
            <el-main style="height: 600px;">
                <!-- <el-row>
                    <el-col :span="20">
                        <el-button-group>
                 
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                        </el-button-group>
                    </el-col>
                </el-row> -->
                <template #default>
                    <div>上传多张</div>
                    <br />
                    <el-upload
                        :action="uploadUrl"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        multiple   
                        :limit="20"  
                        :on-exceed="handleExceed"   
                        :file-list="fileList"   
                        :before-upload="beforeUp"  
                        list-type="picture-card"
                        >
                    <i class="el-icon-plus"></i>
                </el-upload>

                        
                </template>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_bas_imagesdetail",
        extends: ax.control1(),
        data() {
            return {
                vouchertype: "detail",
                mastertable: "ax_bas_images",
                model: "chanjet",
                settings: "axBasImages",
                fileList: [],
                uploadUrl: "",//上传文件的接口地址
                imageUrl: "",
            }
        },
        methods:{
            // handleChange(file,fileList){
            //     this.fileList = fileList;

            //     console.log(file)
            //    // this.DataSet.tables['ax_z_bas_boma'].currentrow.name=file.name;
                
            //     //生成本地图片的url，直接src显示,删除或移动后url就失效了
            //     this.imgUrl = URL.createObjectURL(file.raw);
            //     console.log(this.imgUrl)
            // },
            // uploadUrl:function(param){
            //     this.isImported=true;
            //     var form = new FormData();
            //     form.append("file", param.file);
            //     //var id = this.DataSet.tables["ax_z_bas_boma"].currentrow.guid;
            //     //console.log(id);
            //     var _this=this;
            //     //form.append("id",id);
            //     ax.base.post('/chanjet/axBasImages/upload', form).then(data => {
            //         console.log(data)
            //         _this.DataSet.tables[_this.mastertable].rowsview[0].url = data.data.url;
            //         _this.$XModal.message({ message: data.data, status: 'success' })
            //         }).catch(data => {
            //         _this.$XModal.message({ message: data.data, status: 'error',duration:10000 })
            //     });
            // },
             //限制上传文件的个数提示
            handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                files.length + fileList.length
            } 个文件`);
            },
            //删除之前执行的方法
            beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
            },
            
            //文件删除的方法
            handleRemove(file, fileList) {
            //此处elementui会自动去掉删除的图片，fileList数组会自动清楚删除的图片的地址
            //所以fileList就是删除成功之后的数组，不需要在进行任何处理
            console.log(file, fileList);
            this.fileList = fileList;
            },
                
            /* 上传 */
            beforeUp(file) {
                var _this=this;
                var formData = new FormData();
                formData.append("file", file);  //file是上传的文件
                //调用上传接口xxxxx接口地址
                // console.log(file)

                ax.base.post('/chanjet/axBasImages/uploads', formData).then(data => {
                        console.log(data)
                    //_this.DataSet.tables[_this.mastertable].rowsview[0].url = data.data.url;
                        _this.$XModal.message({ message: "上传成功", status: 'success' })
                    }).catch(data => {
                        _this.$XModal.message({ message: data.data, status: 'error',duration:10000 })
                });


                // this.$http.post("xxxxx", formData).then((res) => {
                // console.log(res);//可获取上传成功返回的地址（图片地址）
                // //yyyyy保存接口
                // return this.$http.post("yyyyy", {
                //     ImageUrl: res.data,
                // });
                // });
            },
        }
    }
</script>

<style type="text/css">
    #t3 > img{
        max-width: 100%;
        /* width: 250px; */
        display: "inline";
        max-height: 100%; 
    }
</style>